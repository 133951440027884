import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import pulseApi from "../../services/pulse-api";
import { logEvent } from "../../services/amplitude";
import exp from "constants";

enum WORKSPACE_TYPE {
  MEMO_GEN = "MEMO_GEN",
}

type Workspace = {
  id: string;
  title: string;
  type: WORKSPACE_TYPE;
  namespace_id: string;
  created_at: string;
};

interface WorkspaceState {
  workspaces: Workspace[];
  currentMemoPageByWorkspaceId: { [key: string]: number | null };
}

const initialState: WorkspaceState = {
  workspaces: [],
  currentMemoPageByWorkspaceId: {},
};

export const fetchWorkspaces = createAsyncThunk(
  "workspace/fetchWorkspaces",
  async ({ namespaceId }: { namespaceId: string }) => {
    const query = `namespace_id=${namespaceId}`;
    const data = await pulseApi.get(`/workspace?${query}`);
    return data;
  }
);

export const createWorkspace = createAsyncThunk(
  "workspace/createWorkspace",
  async ({ title, namespaceId }: { title: string; namespaceId: string }) => {
    const query = `namespace_id=${namespaceId}`;
    const data = await pulseApi.post(`/workspace?${query}`, { title });
    logEvent("workspace_created", {
      workspace_title: title,
      namespace_id: namespaceId,
    });
    return data;
  }
);

export const createWorkspaceDocument = createAsyncThunk(
  "workspace/createNamespaceDocument",
  async ({
    workspaceId,
    documentId,
  }: {
    workspaceId: string;
    documentId: string;
  }) => {
    const query = `document_id=${documentId}`;
    const data = await pulseApi.post(
      `/workspace/${workspaceId}/document?${query}`,
      {}
    );
    return data;
  }
);

export const deleteWorkspace = createAsyncThunk(
  "workspace/deleteWorkspace",
  async ({ workspaceId }: { workspaceId: string }) => {
    const data = await pulseApi.delete(`/workspace/${workspaceId}`);
    return data;
  }
);

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setMemoPageForWorkspace: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        pageNumber: number;
      }>
    ) => {
      const { workspaceId, pageNumber } = action.payload;
      state.currentMemoPageByWorkspaceId[workspaceId] = pageNumber;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        const workspaces = action.payload.workspaces;
        state.workspaces = workspaces;
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        const workspace = action.payload.workspace;
        state.workspaces.push(workspace);
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        const workspaceId = action.meta.arg.workspaceId;
        state.workspaces = state.workspaces.filter(
          (workspace) => workspace.id !== workspaceId
        );
      });
  },
});

// Create a selector to get all workspace ids
export const selectWorkspaceIds = createSelector(
  (state: { workspace: WorkspaceState }) => state.workspace.workspaces,
  (workspaces: Workspace[]) => {
    return workspaces
      .slice() // create a copy to avoid mutating the original array
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      .map((workspace) => workspace.id);
  }
);
// Create a selector to get a workspace by id
export const selectWorkspaceById =
  (workspaceId: string) => (state: { workspace: WorkspaceState }) =>
    state.workspace.workspaces.find(
      (workspace) => workspace.id === workspaceId
    );

  
export const selectCurrentMemoPageNumberByWorkspaceId = 
  (workspaceId: string) => 
  (state: { workspace: WorkspaceState }) => 
    state.workspace.currentMemoPageByWorkspaceId[workspaceId];

export const { setMemoPageForWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;

