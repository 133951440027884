import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../app/store';

import { selectCurrentMemoPageNumberByWorkspaceId, setMemoPageForWorkspace} from '../../features/workspace/workspaceSlice';
import { selectMemoCountByWorkspaceId } from '../../features/workspace-memo/workspaceMemoSlice';

const MemoGenFooter = ({ maxVisibleButtons = 4 }) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: workspaceId = "" } = useParams();

  const totalPages = useSelector(selectMemoCountByWorkspaceId(workspaceId));
  const currentPage = useSelector(selectCurrentMemoPageNumberByWorkspaceId(workspaceId)) || 0;

  // Get the current page from query params or default to 1
  const query = new URLSearchParams(location.search);

  // Function to navigate to a specific page
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      query.set('page', page.toString());
      navigate({ search: query.toString() });
      dispatch(setMemoPageForWorkspace({ workspaceId, pageNumber: page }));
    }
  };

  // Generate page numbers with a single ellipsis
  const renderPageNumbers = () => {
    const pageNumbers: React.ReactNode[] = [];
    const halfVisible = Math.floor((maxVisibleButtons - 1) / 2); // Exclude first/last page and ellipses

    let startPage = Math.max(currentPage - halfVisible, 2); // Ensure we start after page 1
    let endPage = Math.min(currentPage + halfVisible, totalPages - 1); // Ensure we end before last page

    // Adjust start and end for small page ranges
    if (currentPage <= halfVisible + 1) {
      startPage = 2;
      endPage = Math.min(maxVisibleButtons, totalPages - 1);
    } else if (currentPage >= totalPages - halfVisible) {
      startPage = Math.max(totalPages - (maxVisibleButtons - 2), 2);
      endPage = totalPages - 1;
    }

    // Always show the first page
    pageNumbers.push(
      <button
        key={1}
        className={`w-8 h-8 border rounded-full mx-1 text-xs ${currentPage === 1
          ? 'bg-dark-purple-800 text-white'
          : 'text-gray-700 hover:bg-brown-grey-400'
        }`}
        onClick={() => goToPage(1)}
      >
        1
      </button>
    );

    // Add left ellipsis if needed
    if (startPage > 2) {
      pageNumbers.push(
        <span key="left-ellipsis" className="w-8 h-8 flex items-center justify-center border rounded-full mx-1 text-gray-500 text-xs">
          ...
        </span>
      );
    }

    // Add page numbers in the middle
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`w-8 h-8 border rounded-full mx-1 text-xs ${currentPage === i
            ? 'bg-dark-purple-800 text-white'
            : 'text-gray-700 hover:bg-brown-grey-400'
          }`}
          onClick={() => goToPage(i)}
        >
          {i}
        </button>
      );
    }

    // Add right ellipsis if needed
    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <span key="right-ellipsis" className="w-8 h-8 flex items-center justify-center border rounded-full mx-1 text-gray-500 text-xs">
          ...
        </span>
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          className={`w-8 h-8 border rounded-full mx-1 text-xs ${currentPage === totalPages
            ? 'bg-dark-purple-800 text-white'
            : 'text-gray-700 hover:bg-brown-grey-400'
          }`}
          onClick={() => goToPage(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  if( totalPages <= 0) {
    return null;
  }

  return (
    <div className="flex justify-end">
      <div className="inline-flex items-center">
        {/* Previous Button */}
        <button
          className={`w-8 h-8 border rounded-full mx-1 text-xs ${
            currentPage === 1
              ? 'text-gray-300 cursor-not-allowed'
              : 'text-gray-700 hover:bg-brown-grey-400'
          }`}
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ←
        </button>

        {/* Page Numbers */}
        {renderPageNumbers()}

        {/* Next Button */}
        <button
          className={`w-8 h-8 border rounded-full mx-1 text-xs ${
            currentPage === totalPages
              ? 'text-gray-300 cursor-not-allowed'
              : 'text-gray-700 hover:bg-brown-grey-400'
          }`}
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          →
        </button>
      </div>
    </div>
  );
};

export default MemoGenFooter;
