import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import pulseApi from "../../services/pulse-api";
import { logEvent } from "../../services/amplitude";

const mockWorkspaceMemoSections = [
  {
    id: "1",
    workspace_memo_id: "1",
    title: "Problem",
    order: 1,
    data: {
      questions: ["What is the problem?"],
      format: "### The Problem...",
      raw_draft_context: "Question: ... Answer: ...",
    },
    draft: "### The Problem...",
    created_at: "2021-08-01T00:00:00Z",
  },
];

export type WorkspaceMemoSection = {
  id: string;
  workspace_memo_id: string;
  title: string;
  order: number;
  data: {
    questions: string[];
    format: string;
    raw_draft_context: string;
  };
  draft: string;
  created_at: string;
};

interface WorkspaceMemoSectionState {
  workspaceMemoSections: { [memoId: string]: WorkspaceMemoSection[] };
}

const initialState: WorkspaceMemoSectionState = {
  workspaceMemoSections: {},
};

export const fetchWorkspaceMemoSections = createAsyncThunk(
  "workspaceMemoSection/fetchWorkspaceMemoSections",
  async ({ workspaceId, workspaceMemoId }: { workspaceId: string, workspaceMemoId: string }) => {
    const data = await pulseApi.get(`/workspace/${workspaceId}/memo/${workspaceMemoId}/section`);
    return data;
  }
);

export const fetchWorkspaceMemoSection = createAsyncThunk(
  "workspaceMemoSection/fetchWorkspaceMemoSection",
  async ({ workspaceId, workspaceMemoId, sectionId }: { workspaceId: string, workspaceMemoId: string, sectionId: string }) => {
    const data = await pulseApi.get(`/workspace/${workspaceId}/memo/${workspaceMemoId}/section/${sectionId}`);
    return data;
  }
);

export const updateWorkspaceMemoSectionTemplateData = createAsyncThunk(
  "workspaceMemoSection/updateWorkspaceMemoSectionTemplateData",
  async ({ workspaceId, workspaceMemoId, sectionId, update }: {workspaceId: string, workspaceMemoId: string, sectionId: string, update: { data: { questions: string[], format: string } } }) => {
    const response = await pulseApi.patch(`/workspace/${workspaceId}/memo/${workspaceMemoId}/section/${sectionId}`, update);
    logEvent("Workspace Memo Section Updated", { sectionId });
    return response;
  });

export const createSectionDraft = createAsyncThunk(
  "workspaceMemoSection/createSectionDraft",
  async ({ workspaceId, workspaceMemoId, sectionId }: { workspaceId: string, workspaceMemoId: string, sectionId: string }) => {
    const response = await pulseApi.post(`/workspace/${workspaceId}/memo/${workspaceMemoId}/section/${sectionId}/draft`, {});
    logEvent("Workspace Memo Section Generate Requested", { sectionId });
    return response;
  });


export const workspaceMemoSectionSlice = createSlice({
  name: 'workspaceMemoSection',
  initialState,
  reducers: {
    updateWorkspaceMemoSection: (state, action: PayloadAction<WorkspaceMemoSection>) => {
      const memoSection = action.payload;
      const existingSection = state.workspaceMemoSections[memoSection.workspace_memo_id] || [];
      state.workspaceMemoSections[memoSection.workspace_memo_id] = [
        ...existingSection.filter((section) => section.id !== memoSection.id),
        memoSection,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkspaceMemoSections.fulfilled, (state, action) => {
      const memoId = action.meta.arg.workspaceMemoId;
      const memoSections = action.payload.memo_sections;
      state.workspaceMemoSections[memoId] = memoSections;
    });
    
    builder.addCase(fetchWorkspaceMemoSection.fulfilled, (state, action) => {
      const memoId = action.meta.arg.workspaceMemoId;
      const memoSection = action.payload;
      const existingSections = state.workspaceMemoSections[memoId] || [];
      state.workspaceMemoSections[memoId] = [
        ...existingSections.filter((section) => section.id !== memoSection.id),
        memoSection,
      ];
    });

    builder.addCase(updateWorkspaceMemoSectionTemplateData.fulfilled, (state, action) => {
      const response = action.payload;
      const memoSection = response.memo_section;

      const existingSections = state.workspaceMemoSections[memoSection.workspace_memo_id] || [];
      state.workspaceMemoSections[memoSection.workspace_memo_id] = [
        ...existingSections.filter((section) => section.id !== memoSection.id),
        memoSection,
      ];
    });

    builder.addCase(createSectionDraft.fulfilled, (state, action) => {
      const response = action.payload;
      const memoSection = response.memo_section;

      const existingSections = state.workspaceMemoSections[memoSection.workspace_memo_id] || [];
      state.workspaceMemoSections[memoSection.workspace_memo_id] = [
        ...existingSections.filter((section) => section.id !== memoSection.id),
        memoSection,
      ];
    });
  },
});

export const selectSectionBySectionId = (sectionId: string) =>
  (state: { workspaceMemoSection: WorkspaceMemoSectionState }) => {
    const sections = state.workspaceMemoSection.workspaceMemoSections;
    
    return Object.values(sections).flat().find((section) => section.id === sectionId);
  };

export const selectSectionsByMemoId =
  (memoId: string) => (state: { workspaceMemoSection: WorkspaceMemoSectionState }) => {
    const sections = state.workspaceMemoSection.workspaceMemoSections[memoId];
    if (!sections) {
      return [];
    }

    return sections
      .map((section) => section)
      .sort((a, b) => a.order - b.order);
  };

export default workspaceMemoSectionSlice.reducer;
