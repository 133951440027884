/**
 * A utility class for calling the Pulse API. This class is a singleton.
 * When authentication is performed, it stores the token in memory and persists the auth object in storage and uses it for subsequent requests.
 *
 * Once this module is imported, it will automatically load the JWT token from storage if it exists.
 *
 * The auth object is stored in the following format:
 * {
 *    user_id: string,
 *    token: string(JWT token)
 * }
 *
 * The auth object is stored in the local storage under the key 'pulse_auth'.
 */

import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { setCurrentUserId, logEvent } from "./amplitude";

type RequestOptions = {
  responseType: 'json' | 'arraybuffer' | 'blob'
}

const PULSE_API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030"
    : "https://api.usepulse.co";
const AUTH = "pulse_auth";

export interface PulseAuth {
  user_id: string;
  token: string;
}

export interface LoginResponse {
  user: {
    id: string;
    email: string;
  };
  token: string;
}

class PulseAPI {
  private static instance: PulseAPI;
  private auth: PulseAuth | null = null;

  private constructor() {
    this.loadAuth();
  }

  public static getInstance(): PulseAPI {
    if (!PulseAPI.instance) {
      PulseAPI.instance = new PulseAPI();
    }

    return PulseAPI.instance;
  }

  public getCurrentUserId(): string | null {
    return this.auth ? this.auth.user_id : null;
  }

  public isAuthenticated(): boolean {
    return this.auth !== null;
  }

  public async login(email: string, password: string): Promise<LoginResponse> {
    const response = await axios.post(`${PULSE_API_URL}/auth/login`, {
      email,
      password,
    });

    this.auth = {
      user_id: response.data.user.id,
      token: response.data.token,
    };
    
    this.saveAuth();
    const { default: pulseWebSocket } = await import("./pulse-websocket");
    pulseWebSocket.connect();

    return response.data;
  }

  public async get(url: string, requestOptions?: RequestOptions, {responseWithHeaders = false}: {responseWithHeaders?: boolean} = {}): Promise<any> {
    return this.request("get", url, undefined, requestOptions, { responseWithHeaders });
  }

  public async post(url: string, data: any, requestOptions?: RequestOptions): Promise<any> {
    return this.request("post", url, data, requestOptions);
  }

  public async put(url: string, data: any, requestOptions?: RequestOptions): Promise<any> {
    return this.request("put", url, data, requestOptions);
  }

  public async patch(url: string, data: any, requestOptions?: RequestOptions): Promise<any> {
    return this.request("patch", url, data, requestOptions);
  }

  public async delete(url: string): Promise<any> {
    return this.request("delete", url);
  }

  private async request(
    method: "get" | "post" |"put" | "patch" | "delete",
    url: string,
    data?: any,
    requestOptions : RequestOptions = { responseType: 'json' },
    { responseWithHeaders = false } = {}
  ): Promise<any> {
    if (!this.auth) {
      throw new Error("Not authenticated");
    }

    const response = await axios.request({
      method,
      url: `${PULSE_API_URL}${url}`,
      headers: {
        "pulse-token": this.auth.token,
      },
      data,
      responseType: requestOptions.responseType,
    });

    if (responseWithHeaders) {
      return {
        data: response.data,
        headers: response.headers
      }
    }

    return response.data;
  }

  public getToken(): string | null {
      return this.auth ? this.auth.token : null;
  }

  private loadAuth(): void {
    const authStr = localStorage.getItem(AUTH);
    if (authStr) {
      const { token } = JSON.parse(authStr);

      // check if the token is still valid
      const decoded = jwtDecode(token) as any;
      if (decoded && decoded.exp * 1000 > Date.now()) {
        this.auth = JSON.parse(authStr);
        if (this.auth) {
          setCurrentUserId(this.auth.user_id);
          logEvent("session_resumed", { user_id: this.auth.user_id });
        }
      } else {
        localStorage.removeItem(AUTH);
        this.auth = null;
      }
    }
  }

  private saveAuth(): void {
    if (this.auth) {
      localStorage.setItem(AUTH, JSON.stringify(this.auth));
    }
  }
}

// Initialize and export the singleton instance
const pulseAPI = PulseAPI.getInstance();
export default pulseAPI;
