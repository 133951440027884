import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TimeAgo from 'react-timeago'
import ClipLoader from "react-spinners/ClipLoader";
import bytes from 'bytes';
import { useDispatch } from 'react-redux';

import { 
  selectDocumentTitleByDocumentId, 
  selectDocumentCreationDateByDocumentId,
  selectDocumentFileSizeByDocumentId,
  downloadDocument,
  deleteDocument,
} from '../../features/document/documentSlice';
import { selectDocumentStatusNameByDocumentId } from '../../features/document/documentProcessingStatusSlice';
import { RootState, AppDispatch} from '../../app/store';
import { ReactComponent as TrashCanIcon } from '../../assets/trash-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon-2.svg';

interface DocumentFileCapsuleProps {
    documentId: string;
}

const DocumentFileCapsule: React.FC<DocumentFileCapsuleProps> = ({ documentId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const title = useSelector(selectDocumentTitleByDocumentId(documentId));
    const currentStatus = useSelector((state: RootState) => selectDocumentStatusNameByDocumentId(state, documentId));
    const createdAt = useSelector(selectDocumentCreationDateByDocumentId(documentId));
    const fileSizeBytes = useSelector(selectDocumentFileSizeByDocumentId(documentId));
    const formattedFileSize = bytes(fileSizeBytes, { unitSeparator: ' ' });
    const [requestingDelete, setRequestingDelete] = useState(false);
    
    if(!title){
      return null;
    }

    const handleDeleteDocument = async () => {
      setRequestingDelete(true);
      await dispatch(deleteDocument({documentId}));
      setRequestingDelete(false);
    }

    const handleDownloadDocument = async () => {
      try {
        const { blob, headers } = await dispatch(downloadDocument({ documentId })).unwrap();

        // Extract the filename from Content-Disposition header
        const contentDisposition = headers['content-disposition'] || '';
        const fileName = contentDisposition.split('filename=')[1]?.replace(/"/g, '') || 'file';
        
        // Save the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the Blob URL
        
      } catch (error) {
        console.error('Error downloading document:', error);
        alert('Failed to download the document. Please try again later.');
      }
    }

    let dateContent = <TimeAgo date={createdAt} /> 
    let docStatusColorContent = <div> </div>
    let docStatusText = <div> </div>
    switch (currentStatus) {
      case 'COMPLETED':
        docStatusColorContent = <span className="bg-green-500 h-2 w-2 rounded-full" />
        docStatusText = <span className="text-gray-800 text-xs font-semibold"> Completed </span>
        break;
      case 'DELETE_STARTED':
        docStatusColorContent = <span className="bg-red-500 h-2 w-2 rounded-full" />
        docStatusText = <span className="text-gray-700 text-xs font-normal">
          <span>Deleting</span>
          <ClipLoader className='mx-2 opacity-40' size={10} color='#27174B' />
        </span>
        break;
      default:
        docStatusColorContent = <span className="bg-orange-500 h-2 w-2 rounded-full" />
        docStatusText = <span className="text-gray-700 text-xs font-normal">
          <span>Processing</span>
          <ClipLoader className='mx-2 opacity-40' size={10} color='#27174B' />
        </span>
        break;
    }

    return (
      <div className="w-full relative group" title={title}>
        <div className='bg-brown-grey-500 px-4 p-2 rounded-xl w-full mb-2 flex justify-between group-hover:w-4/5 transition-all duration-200'>
          <div className='w-full'>
            <div className="w-48 text-xs font-medium text-gray-700 truncate overflow-hidden whitespace-nowrap">{title}</div>
            <div className='text-xxs text-gray-500'>{formattedFileSize}</div>
            <div className='w-full flex flex-row justify-between items-center'>
              <div className="flex items-center space-x-2 my-1">
                {docStatusColorContent}
                {docStatusText}
              </div>
              <div className="text-gray-500 text-xxs"> {dateContent} </div>
            </div>  
          </div>
        </div>
        <div className='w-1/5 h-full rounded-xl absolute right-0 top-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200'>
          <div className='w-full h-full flex flex-wrap flex-row justify-center items-center'>
            <button
              onClick={handleDownloadDocument} 
              className='mr-1 mb-1 rounded-lg bg-purple-950 opacity-70 p-2 duration-200 cursor-pointer hover:opacity-100'
            >
              <DownloadIcon className='w-3 h-3 text-white' />
            </button>
            <button 
              onClick={handleDeleteDocument} 
              disabled={requestingDelete || currentStatus === 'DELETE_STARTED'} 
              className={`rounded-lg bg-red-500 opacity-70 p-2 duration-200 mr-1 mb-1 ${
                requestingDelete ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:opacity-100'
              }`}
            >
              <TrashCanIcon className='w-3 h-3 text-white' />
            </button>
          </div>
        </div>
      </div>
    );
}

export default DocumentFileCapsule;