import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import pulseApi from '../../services/pulse-api';

interface User {
  id: string;
  email: string;
  organization_id: string;
  first_name?: string;
  last_name?: string;
  config?: {
    can_approve_memo: boolean;
  }
}

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

export const login = createAsyncThunk<any, { email: string, password: string }>(
  'user/login',
  async ({ email, password }) => {
    const data = await pulseApi.login(email, password);

    return data;
  }
);

export const fetchCurrentUser = createAsyncThunk<any, void>(
  'user/fetchCurrentUser',
  async () => {
    const currentUserId = pulseApi.getCurrentUserId();
    const data = await pulseApi.get(`/user/${currentUserId}`);

    return data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });

    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });
  }
});

export const selectUser = (state: { user: UserState }) => state.user.user;

export default userSlice.reducer;
