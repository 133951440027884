import { Middleware } from 'redux';
import pulseWebsocket from '../services/pulse-websocket';
import { DocumentStatus, documentProcessingStatusSlice } from '../features/document/documentProcessingStatusSlice';
import { documentSlice } from '../features/document/documentSlice';
import { workspaceMemoSlice } from '../features/workspace-memo/workspaceMemoSlice';
import { workspaceMemoSectionSlice } from '../features/workspace-memo-section/workspaceMemoSectionSlice';

const REALTIME_EVENTS = {
    DOCUMENT_PROCESSING_STATUS_CHANGE: 'document_processing_status_change',
    MEMO_DRAFT_GENERATION_STATUS_CHANGE: 'memo_draft_generation_status_change',
    MEMO_SECTION_NEW_DRAFT_GENERATED: 'memo_section_new_draft_generated',
    MEMO_CONFIG_UPDATE: 'memo_config_update',
}

const realtimeMiddleware: Middleware = (store) => (next) => {
    pulseWebsocket.on(REALTIME_EVENTS.DOCUMENT_PROCESSING_STATUS_CHANGE, (data: DocumentStatus) => {
        store.dispatch(documentProcessingStatusSlice.actions.updateDocumentProcessingStatus(data));
        store.dispatch(documentSlice.actions.handleStatusChange(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_DRAFT_GENERATION_STATUS_CHANGE, (data: any) => {
        store.dispatch(workspaceMemoSlice.actions.updateWorkspaceMemo(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_SECTION_NEW_DRAFT_GENERATED, (data: any) => {
        store.dispatch(workspaceMemoSectionSlice.actions.updateWorkspaceMemoSection(data));
    });

    pulseWebsocket.on(REALTIME_EVENTS.MEMO_CONFIG_UPDATE, (data: any) => {
        store.dispatch(workspaceMemoSlice.actions.updateWorkspaceMemoConfig(data));
    })

    return next;
};

export default realtimeMiddleware;
