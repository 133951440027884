import { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";

import { AppDispatch, RootState } from '../../app/store';
import { selectCurrentMemoPageNumberByWorkspaceId } from '../../features/workspace/workspaceSlice';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { selectCountNonCompletedDocumentsByIds } from '../../features/document/documentProcessingStatusSlice';
import { selectDocumentIdsByTemplateId, fetchTemplateDocuments } from '../../features/document/documentSlice';
import { selectNameByTemplateId } from '../../features/template/templateSlice';
import { selectSectionsByMemoId } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';
import { fetchWorkspaceMemos, selectMemoByPageNumber, selectMemoCountByWorkspaceId, updateConfig } from '../../features/workspace-memo/workspaceMemoSlice';
import { fetchWorkspaceMemoSections } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';
import { selectUser } from '../../features/user/userSlice'; 

const isDebugMode = localStorage.getItem('pulse-app-debug') === 'true';

const MemoGenDraft = () => {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const { id: workspaceId = "" } = useParams();
    const pageNumber = useSelector(selectCurrentMemoPageNumberByWorkspaceId(workspaceId));
    const memo = useSelector(selectMemoByPageNumber(workspaceId, pageNumber || 0));
    const memoCount = useSelector(selectMemoCountByWorkspaceId(workspaceId));
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const [selectedTemplateId, setCurrentTemplateId] = useState<string | undefined>(memo?.template_id);
    const templateDocumentIds = useSelector(selectDocumentIdsByTemplateId(selectedTemplateId || ''));
    const memoSections = useSelector(selectSectionsByMemoId(memo?.id || ''));
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState<string>('');
    const [approveLoading, setApproveLoading] = useState(false);
    const user = useSelector(selectUser);
    const unCompletedTemplateDocsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, templateDocumentIds));
    const templateName = useSelector(selectNameByTemplateId(selectedTemplateId || ''));

    const sectionsCompleted = memoSections.length > 0 && memoSections.every((section) => section.draft);
    const shouldShowApproveButton = user?.config?.can_approve_memo;
    const shouldShowInReview = !memo?.config?.approved && memoCount === 1 && !shouldShowApproveButton;
    
    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspaceMemos({ workspaceId }));
            setCurrentTemplateId(memo?.template_id);
        }
    }, [dispatch, workspaceId, memo?.template_id]);

    useEffect(() => {
        const memoId = memo?.id;
        if(memoId) {
            dispatch(fetchWorkspaceMemoSections({ workspaceId, workspaceMemoId: memoId }));
        }
    }, [dispatch, workspaceId, memo?.id]);

    useEffect(() => {
        if(selectedTemplateId && currentNamespaceId) {
            dispatch(fetchTemplateDocuments({ namespaceId: currentNamespaceId, templateId: selectedTemplateId }));
        }
    }, [dispatch, selectedTemplateId, currentNamespaceId]);

    const handleApprove = async () => {
        if(approveLoading && !memo?.id) return;

        setApproveLoading(true);

        await dispatch(updateConfig({ workspaceId, memoId: memo.id, config: { approved: !memo.config.approved } }));

        setApproveLoading(false);
    }

    const approveClass = memo?.config?.approved 
    ? 'bg-baby-green-300 text-baby-green-500 text-sm px-6 py-2 rounded-xl ml-2' 
    : 'bg-brown-grey-700 text-gray-500 text-sm px-6 py-2 rounded-xl ml-2 transition duration-200 ease-in-out';
    const approveText = memo?.config?.approved ? 'Approved' : 'Approve';
    const approveContent = approveLoading 
        ? (
            <>
                <div className='flex flex-row items-center justify-center w-10 h-5'>
                    <PulseLoader size="4px" color="gray" speedMultiplier={1.2} />
                </div>
            </>
        ) 
        : approveText;

    const approvalButton = (
        <button 
            className={approveClass}
            onClick={handleApprove}
        >
            {approveContent}
        </button>
    );


    let memoContent = <div className='text-gray-400 text-sm'> Your draft will appear here once generated. </div>;
    let downloadButtonContent;
    
    if(selectedTemplateId && templateDocumentIds.length === 0) { 
        memoContent = <div className='text-gray-400 text-sm'> The selected template: {templateName} does not have any documents.<br/>
        <NavLink
            to={`?modal=manage-templates&modal_template_id=${selectedTemplateId}`}
            className='text-grey-500 font-semibold underline'
        > Add documents to the template
        </NavLink>
        &nbsp;or select another template.
        </div>;
    } else if(unCompletedTemplateDocsCount > 0) {
        memoContent = <div className='text-gray-400 text-sm'> Some documents for the selected template are still being processed, please wait... </div>;
    }

    const handleOpen = (content: string) => {
        if(isDebugMode) {
            setModalContent(content); // Set the content for the modal
            setOpen(true); // Open the modal
        }
    };

    const handleClose = () => {
        setOpen(false); // Close the modal
    };

    if (shouldShowInReview) {
        return <div className='flex flex-col items-center justify-center py-10 px-4 mt-6 bg-brown-grey-500 rounded-xl'>
            <p className='text-gray-500 text-center text-sm'>
                {sectionsCompleted ? 
                    <>
                        {'Report draft has been generated and is currently under review.'}<br />
                        {'Please wait for the review to be completed.'}<br />
                        <ClipLoader
                            size="20px"
                            color="gray"
                            className='mt-2'
                            speedMultiplier={1.2}
                        />
                    </> 
                    : 
                    <>
                        {'Your first draft is being generated.'}<br />
                        <PulseLoader
                            size="4px"
                            color="gray"
                            className='mt-2'
                            speedMultiplier={1.2}
                        />
                    </>
                }
            </p>
            
        </div>
    }

    if(memoSections.length > 0) {
        memoContent = (
            <div className="text-md w-full prose">
                {memoSections.map((section, idx) => {
                    const tooltipContent = section.data ?`
                        ------------ Q & A ------------
                        ${section.data.raw_draft_context}
                        
                        ------------ Format ------------
                        ${section.data.format}
                    `: 'Unavailable';
    
                    return (
                        <div
                            key={idx}
                            className="cursor-pointer"
                            onClick={() => handleOpen(tooltipContent)}
                        >
                            {section.draft ? (
                                <ReactMarkdown
                                    className="w-full markdown"
                                    rehypePlugins={[rehypeRaw]}
                                    remarkPlugins={[remarkGfm]}
                                >
                                    {section.draft}
                                </ReactMarkdown>
                            ) : (
                                <div className="text-gray-500 flex flex-row items-center">
                                    <span>{section.title}</span>
                                    <PulseLoader
                                    size="4px"
                                    color="gray"
                                    speedMultiplier={1.2}
                                />
                                    </div>
                            )}
                        </div>
                    );                    
                })}
            </div>
        );
    }

    const query = new URLSearchParams(location.search);
    query.set('modal', 'draft_template');
    query.set('modal_memo_id', memo?.id || '');
    const editLink = `?${query.toString()}`;

    if(!memo) {
        return <div className='py-10'> {memoContent} </div>;
    }

    return (
        <div className='flex flex-row justify-between items-center px-6 mr-10 bg-brown-grey-500 rounded-xl w-full'>
            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                       Section Debug Info
                    </Typography>
                    <Typography
                        id="modal-description"
                        sx={{ height: '500px', overflowY: 'scroll' }}
                    >
                        <pre>{modalContent}</pre>
                    </Typography>
                </Box>
            </Modal>
            <div className='flex flex-col w-full relative'>
            {/* A div containing a single row and two columns, the first column is two rows: Heading 1 on top and text under. The second column in a centered button saying edit */}
            <div className='font-semibold w-full py-4'>
                <div className='flex flex-row w-full'>
                    <div className='flex flex-col w-full justify-between'>
                        <div className='flex flex-row items-center'> 
                            {templateName}
                        </div>
                        {/* If draft status is not completed, show loading spinner */}
                        {
                            memoSections.length > 0 && memoSections.every((section) => section.draft) ? (
                                <p className='text-gray-500'>
                                    Draft completed
                                </p>
                            ) : (
                                <div className="flex flex-col text-gray-500">
                                    { memo && (
                                        <>
                                            <p> Generating memo draft </p>
                                            <PulseLoader
                                                size="4px"
                                                color="#27174B"
                                                speedMultiplier={1.2}
                                            />
                                        </>
                                    )}
                                </div>
                            )
                        }

                    </div>
                    <div className='flex justify-center items-center'>
                        <NavLink
                            className='bg-baby-purple-300 text-baby-purple-600 text-sm px-6 py-2 rounded-xl ml-2'
                            to={editLink}>
                                Edit
                        </NavLink>
                        {shouldShowApproveButton === true && approvalButton}
                    </div>
                </div>
            </div>
            <div className='overflow-hidden overflow-y-scroll' style={{ height: '50vh' }}>
                {memoContent}
            </div>
            {downloadButtonContent}
            </div>
        </div>
    );
}

export default MemoGenDraft;