import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';

import { AppDispatch } from '../../app/store';
import { selectSectionsByMemoId, selectSectionBySectionId, updateWorkspaceMemoSectionTemplateData, createSectionDraft } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';

const MemoGenTemplateModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const memoId = query.get('modal_memo_id');
    const currentSectionId = query.get('modal_section_id');

    const sections = useSelector(selectSectionsByMemoId(memoId || ''));
    const currentSection = useSelector(selectSectionBySectionId(currentSectionId || ''));

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [sectionDropdownOpen, setSectionDropdownOpen] = useState<boolean>(false);
    const [questions, setQuestions] = useState<string[]>([]);
    const [formatting, setFormatting] = useState<string>('');
    const [originalQuestions, setOriginalQuestions] = useState<string[]>([]);
    const [originalFormatting, setOriginalFormatting] = useState<string>('');
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        const modal = query.get('modal');
        if (modal === 'draft_template') {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [query]);

    useEffect(() => {
        if (currentSection && currentSection.data) {
            setQuestions(currentSection.data.questions || []);
            setOriginalQuestions(currentSection.data.questions || []);
            setFormatting(currentSection.data.format || '');
            setOriginalFormatting(currentSection.data.format || '');
        }
    }, [currentSection]);

    useEffect(() => {
        if (!currentSection) {
            const firstSection = sections[0];
            if (firstSection &&  firstSection.data) {
                const query = new URLSearchParams(location.search);
                query.set('modal', 'draft_template');
                query.set('modal_memo_id', memoId || '');
                query.set('modal_section_id', firstSection.id);
                navigate(`?${query.toString()}`);
            }
        }
    }, [currentSection, sections, navigate, memoId]);

    useEffect(() => {
        const questionsChanged = JSON.stringify(questions) !== JSON.stringify(originalQuestions);
        const formattingChanged = formatting !== originalFormatting;
        setHasChanges(questionsChanged || formattingChanged);
    }, [questions, formatting, originalQuestions, originalFormatting]);

    const handleQuestionChange = (index: number, value: string) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index] = value;
            return updatedQuestions;
        });
    };

    const handleAddQuestion = () => {
        setQuestions((prevQuestions) => [...prevQuestions, '']);
    };

    const handleRemoveQuestion = (index: number) => {
        setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
    };

    const handleDropdownToggle = () => {
        setSectionDropdownOpen(!sectionDropdownOpen);
    };

    const handleSectionSelect = (sectionId: string) => {
        const query = new URLSearchParams(location.search);
        query.set('modal', 'draft_template');
        query.set('modal_memo_id', memoId || '');
        query.set('modal_section_id', sectionId);
        navigate(`?${query.toString()}`);
        setSectionDropdownOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
        setHasChanges(false);
        navigate('.');
    }

    const handleSave = async () => {
        if (currentSection) {
            setIsSaving(true);
            const data = {
                questions,
                format: formatting,
            };
            await dispatch(updateWorkspaceMemoSectionTemplateData({
                workspaceId: memoId || '',
                workspaceMemoId: memoId || '',
                sectionId: currentSection.id,
                update: { data },
            }));

            await dispatch(createSectionDraft({
                workspaceId: memoId || '',
                workspaceMemoId: memoId || '',
                sectionId: currentSection.id,
            }));
            setIsSaving(false);
            navigate('.');
        }
    }

    const sectionList = sections.filter((section) => section.data).map((section) => (
        <div
            key={section.id}
            className="px-4 py-2 hover:bg-brown-grey-700 cursor-pointer text-sm font-normal"
            onClick={() => handleSectionSelect(section.id)}
        >
            {section.title}
        </div>
    ));

    const saveDisabled = !hasChanges || isSaving || sectionList.length <= 0;

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div className="w-3/4 h-4/5 overflow-y-scroll mx-auto mt-10 rounded-lg shadow-lg p-6 space-y-6 bg-[#FCFAF5]">
                <h1 className="text-xl font-bold">Edit Report Template</h1>
                <div className="relative inline-block text-left w-full">
                    <div className="space-y-2">
                        <h3 className="text-lg font-semibold">Sections</h3>
                        <div>
                            <p className="text-sm font-medium text-gray-400"> Select a section to edit </p>
                            <p className="text-sm font-normal text-gray-400">Note: Sections might take a few seconds to load for new reports.</p>
                        </div>
                        
                        <div className="w-2/5 max-w-72">
                            <button
                                type="button"
                                className="inline-flex justify-center w-full rounded-xl border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-brown-grey-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brown-grey-500 focus:ring-brown-grey-700"
                                onClick={handleDropdownToggle}
                            >
                                {currentSection ? currentSection.title : "Select Section"}
                                <svg
                                    className="-mr-1 ml-2 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 3a1 1 0 011 1v8.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L9 12.586V4a1 1 0 011-1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                        {sectionDropdownOpen && sectionList.length > 0 && (
                            <div className="max-h-72 overflow-y-scroll origin-top-right absolute left-0 mt-2 w-56 rounded-xl shadow-lg bg-brown-grey-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">{sectionList}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="space-y-2">
                    <h3 className="text-lg font-semibold">Questions</h3>
                    <p className="text-sm font-normal text-gray-400">
                        These questions extract information from your company documents to fill the report. Edit or add questions to consider more information.
                    </p>
                    <div className="space-y-2">
                        <div className="overflow-y-scroll space-y-2 max-h-72">
                            {questions.map((question, index) => (
                                <div key={index} className="flex items-center space-x-4">
                                    <input
                                        type="text"
                                        value={question}
                                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                                        className="w-full bg-transparent px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-brown-grey-500"
                                    />
                                    <button
                                        className="text-red-500 hover:text-red-700"
                                        onClick={() => handleRemoveQuestion(index)}
                                    >
                                        ✖
                                    </button>
                                </div>
                            ))}
                        </div>
                        <button
                            className="text-purple-500 hover:text-purple-700 font-semibold"
                            onClick={handleAddQuestion}
                        >
                            + Add Question
                        </button>
                    </div>
                </div>
                <div className="space-y-2">
                    <h3 className="text-lg font-semibold mt-10">Formatting</h3>
                    <p className="text-sm font-normal text-gray-400">
                        The format structure is generated from the template files. Edit or add structure for customization.
                    </p>
                    <textarea
                        className="w-full h-32 px-3 py-2 border rounded-lg bg-transparent focus:outline-none focus:ring focus:ring-brown-grey-500"
                        value={formatting}
                        onChange={(e) => setFormatting(e.target.value)}
                    />
                </div>
                <div className="flex justify-center items-center">
                    <button
                        className={`w-1/4 py-2 font-semibold rounded-full transform transition duration-100 ease-in-out ${
                            saveDisabled
                                ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                : 'bg-dark-purple-800 text-white hover:scale-105'
                        }`}
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={saveDisabled}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MemoGenTemplateModal;
